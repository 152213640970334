import { createContext, useEffect, useState } from "react";
import api from "../services/api";

export const AuthContext = createContext()

export const AuthProvider = ({children}) => {

    const [user, setUser] = useState(null)
    const [customer, setCustomer] = useState(null)
    const [subscription, setSubscription] = useState(null)
    const [selectedClientId, setSelectedClientId] = useState(null);
    const [selectedTaskId, setSelectedTaskId] = useState(null);

    console.log(`O usuário ativo é ${JSON.stringify(user)}`)

    const loadingStoredData = async () => {
        try {
            const storageUser = JSON.parse(localStorage.getItem("@Auth:user"));
            const storageToken = localStorage.getItem("@Auth:token");
            const storageName = localStorage.getItem("@Auth:name");
            
            if (storageToken && storageUser && storageName) {
                setUser(storageUser);
                console.log(`dados do usuário: ${JSON.stringify(storageUser)}`)
                console.log(JSON.stringify(localStorage.getItem("@Auth:Id")))
            }
        } catch (error) {
            console.error("Error loading stored data:", error);
        }
    };
    
    useEffect(() => {
        loadingStoredData();
    }, []);

    const setClient = (id) =>{
        setSelectedClientId(id);
    }

    const setTask = (id) => {
        setSelectedTaskId(id)
    }
    const signIn = async ({email, password}) => {
        const response = await api.post("/admin/login", {
            email,
            password,
        });
        setSubscription(response.data.subscriptionStatus);
        console.log(`O status da subscrição é: ${subscription}`)
        if(response.data.error){
            alert(response.data.error)
        } else {
            setUser(response.data.user)
            console.log(`Subscrição: ${response.data.subscriptionStatus}`)
            api.defaults.headers.common[
                'Authorization'
            ] = `Bearer ${response.data.token}`       
            localStorage.setItem("@Auth:token", JSON.stringify(response.data.token))
            localStorage.setItem("@Auth:user", JSON.stringify(response.data.user))
            localStorage.setItem("@Auth:Id", JSON.stringify(response.data.id))
            localStorage.setItem("@Auth:name", (response.data.name))
            console.log(response.data)
            console.log({name: response.data.name})
        }

    }

    const signOut = () => {
        localStorage.clear()
        setUser(null)
    }

    return(
        <AuthContext.Provider value={{
            user,
            subscription,
            selectedClientId,
            selectedTaskId,
            signed: !!user,
            signIn,
            signOut,
            setClient,
            setTask
        }}>
            {children}
        </AuthContext.Provider>
    )
}